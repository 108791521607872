import React from 'react'
import Aux from '../hoc/Auxiliary/Auxiliary';
import styles from './Footer.module.css';

const footer = () => (
    <Aux>
        <div className={styles.Footer}>
            <div className={styles.Footer1}> 
                <h3 className='pl-2 text-center'>Quick Links</h3>
                <ul className='row pl-0 text-center'>
                    <li className='col-6 col-sm-2'><a href='/'>OUR SHOP</a></li>
                    <li className='col-6 col-sm-2'><a href='/'>AUTO REPAIR</a></li>
                    <li className='col-6 col-sm-2'><a href='/'>REPAIR TIPS</a></li>
                    <li className='col-6 col-sm-2'><a href='/'>CONTACT US</a></li>
                    <li className='col-6 col-sm-2'><a href='/'>APPT REQ</a></li>
                    {/* <li className='col-6 col-sm-2'><a href='/'>PRIVACY POLICY</a></li> */}
                    <li className='col-6 col-sm-2'><a href='/'>HOME</a></li>
                </ul>
            </div>
      
        <div className='row pt-3'>
            <div className='col-4'>
                <p>Contact Us</p>
            </div>
            <div className='col-4'>
                <p className="mb-0">50 Liberty Street Batavia, NY 14020</p>
                <p>585-344-7171</p>
            </div>
            <div className='col-4'>
                <p className="text-muted">Copyright 2020 Radiant Soltutions LLC</p>
            </div>
        </div>
        </div>
    </Aux>
)

export default footer;