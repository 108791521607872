import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {BsGear} from 'react-icons/bs';

class TransmissionPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><BsGear/>  Transmission Repair</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need transmission maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your transmission is a vital component, so if you're unsure of it's integrity, please get in touch with us right away!</h4>


        <h4><strong>Key Concepts:</strong></h4>
        
        <p><strong>Automatic Transmission</strong></p>
         <p>Automatic Transmissions shift between gears automatically to optimize driving, and changes gears based on the driver's throttle pedal, vehicle speed, engine speed, and vehicle load. Typical automatic transmissions have 4-5 forward gear ratios, a Reverse, Park, and Neutral gear. Shifting gears occur automatically once the car is in Drive and there is no need for a clutch pedal or gear shift like there is in a Manual Transmission. Automatic transmission repair is complicated based on all the components that make it up, and you need to have any automatic transmission issues properly assessed by auto mechanics. Call 585-344-7171 to talk to the mechanics of Batavia Automotive Inc. about your transmission problems.</p>
        
         <p><strong>Clutches</strong></p>
        <p>There are clutches in both automatic and manual transmission cars, and different types of clutches. When shifting gears, the clutch engages and disengages from the flywheel and transfers the torque through the transmission. Clutches should help your vehicle start and shift gears smoothly. The clutch in your car receives a lot of wear and can eventually wear out. If your clutch begins slipping irregularly or is making noises that are raising suspicion, contact Batavia Automotive Inc. to see if clutch replacement is necessary.</p>
       
        <p><strong>Four-Wheel Drive Transmission</strong></p>
        <p>A four-wheel (4x4) drive vehicle has differential gears, both front and rear axles, and a transfer case attached to the transmission. Four-wheel drive vehicles demand maintenance on the transfer case, front differentials, rear differentials, and transmission fluids. Visit us on 50 Liberty Street for service on your four-wheel drive transmission services.</p>
        
        <p><strong>Front-Wheel Drive Transmission</strong></p>
        <p>If your vehicle has Front-wheel drive, the engine drives the front wheels only. The power is routed through the transmission to the final drive where it is split and sent to the two front wheels through the drive axles. The engine, transmission, and additional hardware is all located in the front of the car. If you are in need, Batavia Automotive Inc. of Batavia, NY will gladly repair front-wheel drive transmission vehicles.</p>
        
        <p><strong>Manual Transmission</strong></p>
        <p>Driving a vehicle with a Manual Transmission requires using the clutch pedal and gear shift to manually shift gears based on the speed of the vehicle. Manual transmissions have been built with anywhere from two to eight gears. Front-wheel drive and rear-wheel drive are the two main configurations for manual transmissions. Typically, manual transmissions require less maintenance then automatic transmissions. At Batavia Automotive Inc., our auto mechanics can diagnose your vehicle and then recommend the transmission repair or transmission service you need to ensure safety and improve the lifetime of your vehicle.</p>
        
        <p><strong>Replacement</strong></p>
        <p>In motor vehicles, the transmission usually refers to the gear box, which uses gears and gear trains to transmit speed and tourque from a rotating power source to another device. You can have your car's transmission replaced with a new, rebuilt, remanufactured, repaired or used transmission. For more information contact Batavia Automotive Inc. in Batavia, NY. Our experienced service technicians can help you make the best decision for your vehicle and your wallet.</p>

        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default TransmissionPage;