import React, {Component} from 'react';


class AboutUs extends Component {

render() {

    return(
        <h1>About US</h1>
    )



}



}


export default AboutUs;