import React, {Component} from 'react';
import {Button, Form } from 'react-bootstrap';
import styles from './BookingForm.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import axios from 'axios';
import Spinner from '../UI/Spinner/Spinner';
import checkmark from '../../assets/images/checkmark.png';
import {BsPerson} from 'react-icons/bs';
import {GoCalendar} from 'react-icons/go';
import {AiOutlineCar} from 'react-icons/ai';


class AppointmentForm extends Component {
    state= {
        loading: false,
        submitted: false,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        desiredDate: "",
        message: "",
        contactMethod: "",
        appointmentType: "",
        year: "",
        make: "",
        model: "",
        OrgId: ""
    }

    
  componentDidMount() {
    this.setState({submitted: false})
  }

  resetForm = () => {
    this.setState({submitted: false})
  }

  firstNameInputHandler = (event) => {
    const updatedFirstName = event.target.value;
    this.setState({firstName: updatedFirstName})
  }
  lastNameInputHandler = (event) => {
    const updatedLastName = event.target.value;
    this.setState({lastName: updatedLastName})
  }
  emailInputHandler = (event) => {
    const updatedEmail = event.target.value;
    this.setState({email: updatedEmail})
  }
  phoneNumberInputHandler = (event) => {
    const updatedPhoneNumber = event.target.value;
    this.setState({phoneNumber: updatedPhoneNumber})
  }
  desiredDateInputHandler = (event) => {
    const updatedDesiredDate = event.target.value;
    this.setState({desiredDate: updatedDesiredDate})
  }
  messageInputHandler = (event) => {
    const updatedMessage = event.target.value;
    this.setState({message: updatedMessage})
  }
  contactMethodInputHandler = (event) => {
    const updatedContactMethod = event.target.value;
    this.setState({contactMethod: updatedContactMethod})
  }
  appointmentTypeInputHandler = (event) => {
    const updatedAppointmentType = event.target.value;
    this.setState({appointmentType: updatedAppointmentType})
  }
  yearInputHandler = (event) => {
    const updatedYear = event.target.value;
    this.setState({year: updatedYear})
  }
  makeInputHandler = (event) => {
    const updatedMake = event.target.value;
    this.setState({make: updatedMake})
  }
  modelInputHandler = (event) => {
    const updatedModel = event.target.value;
    this.setState({model: updatedModel})
  }

  RequestHandler = (event) => {
    event.preventDefault();
    this.setState({loading: true})
    let url = '';

    axios.post(url , {
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber,
      DesiredDate: this.state.desiredDate,
      ContactMethod: this.state.contactMethod,
      AppointmentType: this.state.appointmentType,
      Year: this.state.year,
      Make: this.state.make,
      Model: this.state.model,
      Message: this.state.message,
      OrgId: this.state.OrgId
    })
    .then(response => {
    //   console.log(this.state.artist)
      // console.log(this.request.Artist)
      // console.log(this.request.Title.value)
      // console.log(this.request.OrgId.value)
      console.log(response);
      this.setState({loading: false, submitted: true});
      // this.history.push('/');
      
    })
    .catch(error => {
      console.log(error);
      this.setState({loading: false})
    });
      
  
  }

    render() {
        let bookForm = (
            <div className="container">
            <Form onSubmit={this.RequestHandler} className={styles.Formdiv1}>
            <Form.Group controlId="formBasicName">
                <Form.Label><BsPerson style={{paddingRight: '5px', fontSize: '2.0rem'}}/>Personal</Form.Label>
                <Form.Control value={this.firstName}
                              onChange={(event) => this.firstNameInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="text" 
                              placeholder="First Name" 
                              required/>
                              
            </Form.Group>
            <Form.Group controlId="formBasicLast">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control value={this.lastName}
                              onChange={(event) => this.lastNameInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="text" 
                              placeholder="Last Name" 
                              required/>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                {/* <Form.Label>Email Address</Form.Label> */}
                <Form.Control value={this.email}
                              onChange={(event) => this.emailInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="email" 
                              placeholder="Email Address" 
                              required/>
            </Form.Group>

            <Form.Group controlId="formFilechoice">
                {/* <Form.Label>Phone Number</Form.Label> */}
                <Form.Control 
                              value={this.phoneNumber}
                              onChange={(event) => this.phoneNumberInputHandler(event)} 
                              className="w-100 m-auto" 
                              type="text" 
                              placeholder='Phone Number '  
                              required/>
                              
            </Form.Group>

            <Form.Group controlId="formFilePhone" style={{borderBottom: '.5px solid black', paddingBottom: '10px'}}>
                <Form.Label>Preferred Contact Method: </Form.Label>
                            
                              <select 
                                    style={{marginLeft: '10px'}}
                                    value={this.contactMethod}
                                    onChange={(event) => this.contactMethodInputHandler(event)} 
                                    
                                >
                                <option selected value={this.contactMethod}>Phone Call</option>
                                <option value={this.contactMethod}>Text</option>
                                <option value={this.contactMethod}>Email</option>
                            </select>
                            
            </Form.Group>
            <Form.Label style={{paddingBottom: '10px',}}><GoCalendar style={{paddingRight: '10px',  fontSize: '2.0rem'}}/>Appointment Details </Form.Label>
            <Form.Group controlId="formFilePhone">
                <Form.Label>Preffered Appointment Type:</Form.Label>
                            
                              <select 
                                    style={{marginLeft: '10px'}}
                                    value={this.appointmentType}
                                    onChange={(event) => this.appointmentTypeInputHandler(event)} 
                                    
                                >
                                <option selected value={this.appointmentMethod}>Drop Off</option>
                                <option value={this.appointmentMethod}>Waiting</option>
                             
                            </select>
                            
            </Form.Group>
        
            <Form.Group controlId="formFileDate" style={{borderBottom: '.5px solid black', paddingBottom: '20px'}}>
                <Form.Label>Pick a Desired Date</Form.Label>
                <Form.Control value={this.desiredDate}
                              onChange={(event) => this.desiredDateInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="date"  
                              />
            </Form.Group>

            <Form.Group controlId="formBasicYear">
                <Form.Label><AiOutlineCar style={{paddingRight: '5px', fontSize: '2.0rem'}}/>Vehicle Information</Form.Label>
                <Form.Control value={this.year}
                              onChange={(event) => this.yearInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="text" 
                              placeholder="Year" 
                              required/>
                              
            </Form.Group>
            <Form.Group controlId="formBasicMake">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control value={this.make}
                              onChange={(event) => this.makeInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="text" 
                              placeholder="Make" 
                              required/>
            </Form.Group>
            <Form.Group controlId="formBasicModel" style={{borderBottom: '.5px solid black', paddingBottom: '20px'}}>
                {/* <Form.Label>Email Address</Form.Label> */}
                <Form.Control value={this.model}
                              onChange={(event) => this.modelInputHandler(event)}  
                              className="w-100 m-auto" 
                              type="email" 
                              placeholder="Model" 
                              required/>
            </Form.Group>


            <Form.Group controlId="formBasicMessage">
                <Form.Label>Extra Details (optional)</Form.Label>
                <Form.Control value={this.message}
                              onChange={(event) => this.messageInputHandler(event)}  
                              className="w-100 m-auto" 
                              as ="textarea" 
                              rows ='3'
                              placeholder="Custom Message" 
                              />
            </Form.Group>
            <Form.Text className="text text-center pb-2">
                We look forward to working with you!
                </Form.Text>
                <div className='text-center pb-4'>
                    <Button  variant="success" type="submit"  >
                      Submit
                  </Button>
                </div>
           
        </Form>
   
    </div>
        )

        if(this.state.loading) {
            bookForm = <Spinner />
          }
      
          if(this.state.submitted) {
            bookForm = (
                      <div className="text-center container">
                        <img className={styles.Checkmark1} src={checkmark} alt="success" />
                        <p>Request Submitted Successfully</p>
                        <a className='pb-3' href='/'>Return Home</a>
                      </div>
                        )
                                  }

        return(
            <Aux>
                {bookForm}
            </Aux>
        )
    }
}


  



export default AppointmentForm;