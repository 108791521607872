import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';

class TiresPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron">
        <h1>Tire Service</h1>
        </div>
        </div>
        </Aux>
    )



}



}


export default TiresPage;