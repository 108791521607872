import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';

class InspectionsPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron">
        <h1>NY State Inspections</h1>
        </div>
        </div>
        </Aux>
    )



}



}


export default InspectionsPage;