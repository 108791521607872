import React, {Component} from 'react';
import styles from './ServicesPage.module.css'
import Aux from '../../hoc/Auxiliary/Auxiliary'
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';

class BrakesPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><FaCarAlt/>  Brake Service</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need brake maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your brakes are a vital safety feature, so if you're unsure of their integrity, please get in touch with us right away!</h4>


        <h4><strong>Key Concepts:</strong></h4>
        
        <p><strong>Anti-Lock Brake System</strong></p>
         <p>Anti-lock Brake Systems (ABS) ensure that the wheels don't stop rotating during braking, preventing the car from skidding and offering greater control. If your ABS light comes on, visit Batavia Automotive Inc. where we will be happy to diagnose and fix the problem.</p>
        
         <p><strong>Brake Fluid Flushes</strong></p>
        <p>Brake fluid will absorb water from the air over time, causing the brake system to become less effective and the fluid to become corrosive, possibly damaging the system. It is important to perform a brake fluid flush regularly to ensure that your vehicle is using fresh fluid. Talk to our technicians at Batavia Automotive Inc. about when it's time for a brake fluid flush.</p>
       
        <p><strong>Brake Hoses</strong></p>
        <p>A brake hose is a tube carrying pressurized brake fluid from the master cylinder to the brakes. A crushed hose can cause a lagged or slow brake, and a leak in the hose can cause the brake, or the entire brake system, to fail. These don't need to be replaced often, but should be replaced at the first sign of cracking or wear.</p>
        
        <p><strong>  Brake Pad and Shoe Replacement</strong></p>
        <p>Brake pad problems can usually be identified by squealing brakes. If your brake pads deteriorate completely, you'll hear a grinding metal-on-metal sound when braking, meaning that it's too late and you're ruining your rotors or drums! Those with knowledge of auto repair may be able to fix this at home, but you should always see an auto repair professional immediately if you have brake problems.</p>
        
        <p><strong>Caliper Replacement</strong></p>
        <p>The brake caliper houses your brake pads and fits around the rotor like a clamp, pressing the pads against the rotor when you brake. A brake caliper problem could cause uneven braking, making your car slide forward when you brake. Uneven braking can also cause your vehicle to slide out of control in bad weather conditions, so contact Batavia Automotive Inc. as soon as possible.</p>
        
        <p><strong>Resurface Rotors</strong></p>
        <p>In a disc brake system, rotors are attached to your vehicle's wheels. When the brake pads grip the rotor, they bring both the rotor and wheels to a stop. However, the friction causes grooves and cracks to appear over time. Resurfacing brings the rotor back to a "like-new" condition, reducing squealing and wobbling. Batavia Automotive Inc. will give your rotors a thorough inspection and recommend your best course of action.</p>

        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default BrakesPage;