import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {TiMessages} from 'react-icons/ti';
import styles from './ContactPage.module.css';
import BookingForm from '../../components/Forms/BookingForm';

class ContactPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron">
        
       
        <h1 > <TiMessages style={{paddingRight: '10px',  fontSize: '2.7rem'}}/> Contact Request</h1>
        
        </div>
        <BookingForm/>
        </div>

        
        </Aux>
    )



}



}


export default ContactPage;