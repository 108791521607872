import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './AppointmentPage.module.css';
import AppointmentForm from '../../components/Forms/AppointmentForm';
import {GoCalendar} from 'react-icons/go';

class AppointmentPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron">
       
        <h1 > <GoCalendar style={{paddingRight: '10px',  fontSize: '2.7rem'}}/> Appointment Request</h1>
        
        </div>
        </div>

        <AppointmentForm/>
        </Aux>
    )



}



}


export default AppointmentPage;