import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './Services.module.css';

const Services = () => (
    <Aux>
        <div className='jumbotron'>

            <h2 >Batavia Automotive</h2>
            <p>Consitent Service You Can Rely On!</p>
            
        </div>
        <div className='p-3'>

            <h3>No Appointments taken for Oil Changes - First Come, First Served!</h3>
            <br></br>
            <p>Batavia Automotive Inc. is the automotive expert in Batavia, NY 14020. Since, 2012 we've provided the Batavia area with reliable, affordable automotive maintenance and repair services including 4x4 Services, Brakes, Car and Truck Care, Electric and Hybrid Vehicle , Electrical Services, Electronic Services, Engine and Transmission, Engine Maintenance, Expired Warranty Care, Fleet Brakes, Fleet Drive Train, Fleet Electrical, Fleet Electronics, Fleet Engine Work, Fleet Front End Services, Fleet General Services, Fleet Suspension Work, Fleet Tires, Fleet Wheel Services, General Services, Heating and Cooling Services, Hoses, Ignition Interlock Systems , Inspections and Emissions, Miscellaneous Services, Quick Lube Services, Tires, Transmission Services and Undercar Services.</p>
            <br></br>
            <p>We have experience servicing all makes and models of domestic and import vehicles, and we're honored to keep vehicles throughout the Batavia area reliable and well-maintained. Our skillful mechanics will take great care of your vehicle too, quickly diagnosing any problems and offering cost-effective solutions to get you back on the road.</p>
            <br></br>
            <p>Call Batavia Automotive Inc. today at 585-344-7171! You can also send us an email. Either way, we're looking forward to providing you with reliable automotive service!</p>
        </div>
    </Aux>
)

export default Services;
