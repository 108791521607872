import React, {Component} from 'react';
import BookingForm from '../../components/Forms/BookingForm';
import Aux from '../../hoc/Auxiliary/Auxiliary';
// import styles from './Home.module.css';
import Modal from '../../components/UI/Modal/Modal';
import styles from './Home.module.css'
import {Carousel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import pic1 from '../../assets/images/pic1.jpg';
import pic20 from '../../assets/images/pic20.jpg';
import bapic from '../../assets/images/bapic3.JPG';
import pic2 from '../../assets/images/pic2.jpg';
import pic3 from '../../assets/images/pic3.jpg';
import Services from '../../components/Services/Services';
import Map from '../../components/Map/Map';
import AreasWeServe from '../../components/AreasWeServe';
import ServicesGrid from '../../components/ServicesGrid';
import SimpleForm from '../../components/ChatBot/ChatBot';
import {FaCarAlt} from 'react-icons/fa';
import {FiPhone} from 'react-icons/fi';
import {TiMessages} from 'react-icons/ti';
import {GoCalendar} from 'react-icons/go';
import Reviews from '../../components/Reviews/Reviews';



class HomePage extends Component {

    state = {
        requesting: false,
        chat: false,
        // reset: false
    }

    requestHandler = () => {
      console.log('contact clicked')
        this.setState({requesting: true});
    }

    requestCancelHandler = () => {
        this.setState({requesting: false,});
        console.log("modal closed from home");
        this.refs.child.resetForm();
    }
    chatCancelHandler = () => {
      this.setState({chat: false,});
      console.log("chat closed from home");
     
  }

    chatHandler = () => {
      if (this.state.chat) {
        this.setState({chat: false});
      } else {
        this.setState({chat: true});
      }
      
    }

    



    

    render() {
        return(
            <Aux>
                <Modal show={this.state.chat} modalClosed={this.chatCancelHandler}>
                    <SimpleForm ref="child" />
                </Modal>
                        
                {/* <Modal show={this.state.requesting} modalClosed={this.requestCancelHandler}>
                    <ChatBot ref="child" />
                </Modal> */}
              


               
          <div className={styles.background}>


          <Carousel style={{backgroundColor: 'black'}} className={styles.Clip} controls={false} indicators={false}>
                    
            <Carousel.Item>
              <div >
                <img
                  className={styles.Carou}
                  src={pic1}
                  alt="First slide"
                />
              </div>
              <Carousel.Caption>
              
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
            <div >
                <img
                  className={styles.Carou}
                  // className={"d-block w-100 h-50 card-image-overlay" }
                  src={pic20}
                  alt="First slide"
                />
              </div>
              <Carousel.Caption>
              {/* <img className={styles.Logo} src={logo} alt="logo" />
                <h3>We are the Band</h3>
                <SongButton className='pr-2' clicked={this.requestHandler}/>
                <button className={styles.Tip}>Tip the band</button> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
            <div >
                <img
                  className={styles.Carou}
                  // className={"d-block w-100 h-50 card-image-overlay" }
                  src={pic2}
                  alt="First slide"
                />
              </div>
              <Carousel.Caption>
              {/* <img className={styles.Logo} src={logo} alt="logo" />
                <h3>We are the Band</h3>
                <SongButton className='pr-2' clicked={this.requestHandler}/>
                <button className={styles.Tip}>Tip the band</button> */}
              </Carousel.Caption>
            </Carousel.Item>    
            
          </Carousel>
                      <div className={styles.centered}> 
                    
                          <a style={{textDecoration: 'none', color: 'white'}} href='/Contact'><TiMessages style={{cursor: 'pointer', marginRight: "3.5%"}} /></a>
                          {/* <Button onClick={this.requestHandler}><TiMessages/></Button> */}
                          <a href='tel:585-344-7171'> <FiPhone style={{cursor: 'pointer', marginLeft: "3.5%", marginRight: "3.5%", color: 'rgb(0, 211, 35)'}} /> </a> 
                          <a style={{textDecoration: 'none', color: 'white'}} href='/Appointment'> <GoCalendar style={{marginLeft: "3.5%"}} /></a>
                     
                      </div> 
       
        
                      </div>

                    
                  
                   <div className="float-right fixed-bottom justify-content-right text-right pr-4 pb-4">
                      {/* <ChatBot chatshow={this.state.chat} chatClosed={this.chatCancelHandler}/> */}
                      <div className="float-right fixed-bottom justify-content-right text-right pr-4 pb-4">
                        <button  onClick={this.chatHandler} style={{backgroundColor: 'pink'}} className={styles.FixedButton}><FaCarAlt style={{fontSize: '4.0em'}}/></button>
                      </div>
                  </div>
                
                <Services/>
            
                <ServicesGrid />
             
                <AreasWeServe />
                <Map/>
                {/* <Reviews/> */}

          

                        
          </Aux>
        
        );
    }
 
}

export default HomePage;